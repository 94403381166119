<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "AboutUs",

  metaInfo: { title: "About us" },

  extends: View,

  mixins: [LoadSections(["about-us"])],

  props: {
    id: {
      type: String,
      default: "About",
    },
  },
};
</script>
